@import 'fonts.scss';
@import "variables";

.main-container {
  background: #fff;
  .main {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $app-background-light;
    max-width: $app-max-width;
  }
}
