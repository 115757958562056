@import '../../styles/media.mixins';
@import '../../styles/variables.scss';

footer {
  .motto {
    text-transform: uppercase;
    background: #74bf44;
    color: #fff;
    font-family: ExoSoftLight, sans-serif;
    font-size: $font-size-lg;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include tablet-and-lower {
      font-size: 16px;
    }

    div {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .org {
      min-height: 36px;
      font-size: $font-size-sm;
      width: 100%;
      background: #ebebeb;
      color: #464646;
      text-transform: capitalize;

      p {
        width: 200px;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

.subfooter {
  padding: 25px 55px;
  background: #464646;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet-and-lower {
    flex-direction: column;
    padding: 25px 5px;
  }

  .info {
    text-align: center;
    font-family: ExoSoftLightItalic, sans-serif;
    font-size: 20px;
    max-width: 79%;
    color: #fff;

    .statements-text {
      max-width: 730px;
      margin: auto;
    }

    & > p {
      @include medium-desktop-and-lower {
        margin-bottom: 50px;
      }
    }

    & > p:first-child {
      margin-bottom: 50px;
    }
  }

  .statements {
    color: #fff;
    font-family: ExoSoftMedium, sans-serif;
    text-transform: uppercase;
    font-size: $font-size-md;
    flex-grow: 0;
    flex-shrink: 0;
    width: $link-img-width;
    height: $link-img-height;
    background: url("../../images/Statements.png") no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include tablet-and-lower {
      margin-top: 25px;
      width: $link-img-width-mobile;
      height: $link-img-height-mobile;
      font-size: $font-size-lg;
    }

    p {
      text-align: center;
      transform: scale(1);
      max-width: 80%;
    }

    &:hover {
      p {
        transform: scale(1.05);
        transition: all 300ms;
      }
    }
  }

}