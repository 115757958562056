@font-face {
  font-family: 'ExoSoft';
  src: url(fonts/ExoSoft.otf) format("opentype");
}
@font-face {
  font-family: 'ExoSoftLight';
  src: url(fonts/ExoSoftLight.otf) format("opentype");
}
@font-face {
  font-family: 'ExoSoftLightItalic';
  src: url(fonts/ExoSoftLightItalic.otf) format("opentype");
}
@font-face {
  font-family: 'ExoSoftMedium';
  src: url(fonts/ExoSoftMedium.otf) format("opentype");
}
@font-face {
  font-family: 'ExoSoftItalic';
  src: url(fonts/ExoSoftItalic.otf) format("opentype");
}
@font-face {
  font-family: 'ExoSoftBold';
  src: url(fonts/ExoSoftBold.otf) format("opentype");
}