@import '../../styles/media.mixins';
@import '../../styles/variables';

.main-banner {
  width: 100%;
  min-height: 280px;
  padding: 0;
  background: url("../../images/Banner-2.jpg") no-repeat;
  background-size: cover;
  position: relative;

  .title {
    font-family: "ExoSoftLightItalic", sans-serif;
    font-size: $font-size-xl;
    color: #fff;
    text-align: right;
    margin-bottom: 10px;
    padding-right: 25px;
    padding-left: 25px;
    @include mobile {
      font-size: 60px;
    }
  }

  .subtitle {
    color: #464646;
    text-align: right;
    max-width: 620px;
    margin-left: auto;
    font-size: $font-size-md;
    padding-right: 30px;
    padding-left: 30px;
  }

  .colored-logo {
    width: 200px;
    margin-left: 10px;
    position: absolute;
    bottom: 15px;
    @include tablet-and-lower {
      margin: auto;
      position: static;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  .explanation-wrapper {
    margin-top: 45px;
    position: absolute;
    bottom: -18%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    right: 0;
    width: 57%;
    @include tablet-and-lower {
      width: 100%;
      margin-top: 15px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: initial;
    }
  }

  .explanation {
    padding: 15px;
    font-size: $font-size-sm;
    color: #fff;
    max-width: 1045px;
    background: #eb6159;
    @include mobile {
      font-size: 16px;
    }
  }
}

