$mobile-width: 320px;
$mobile-width-mid: 360px;
$tablet-width: 768px;
$small-desktop-width: 1032px;
$medium-desktop-width: 1324px;
$wide-desktop-width: 1600px;

@mixin mobile {
  @media all and (max-width: #{$mobile-width}){
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px})  {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$small-desktop-width - 1px}) {
    @content;
  }
}

@mixin medium-desktop {
  @media (min-width: #{$small-desktop-width}) and (max-width: #{$medium-desktop-width - 1px}) {
    @content;
  }
}

@mixin wide-desktop {
  @media (min-width: #{$medium-desktop-width})  and (max-width: #{$wide-desktop-width}) {
    @content;
  }
}

@mixin expanded-desktop {
  @media (min-width: #{$wide-desktop-width}){
    @content;
  }
}

@mixin small-desktop-over {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin min-mobile {
  @media (max-width: #{$mobile-width-mid}) {
    @content;
  }
}

@mixin medium-desktop-and-lower{
  @media (max-width: #{$medium-desktop-width}) {
    @content;
  }
}

@mixin tablet-and-lower {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin minimal-header{
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}



