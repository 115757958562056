@import '../../styles/media.mixins';
@import '../../styles/variables';

.key-points-wrapper {
  background: #ebebeb;
  padding-top: 25px;
  .points-list {
    display: flex;
    flex-direction: column;
    color: #58595b;
    font-family: ExoSoftItalic, sans-serif;
    font-size: $font-size-sm;
    &.right-align{
      .heading{
        align-self: flex-end;
      }
      .line{
        margin-left: auto;
        width: 100%;
      }
    }
  }
  .point-item{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
    div{
      width: 45%;
      margin-bottom: 30px;
      @include tablet-and-lower {
        width: 100%;
      }
    }
  }
  .heading{
    font-family: ExoSoftLight, sans-serif;
    font-size: $font-size-xs;
    text-transform: uppercase;
    color: #fff;
    background: #ee635b;
    padding: 10px 30px;
    width: 320px;
    box-sizing: border-box;
    margin-bottom: 20px;
    @include tablet-and-lower {
      width: 100%;
      text-align: center;
    }
    &.right-align{
      align-self: flex-end;
      text-align: right;
    }
  }
  .paragraph-title{
    font-family: ExoSoftBold, sans-serif;
    margin-bottom: 5px;
  }
  .line{
    border: none;
    height: 3px;
    color: #464646;
    background-color: #464646;
    max-width: 530px;
    @include tablet-and-lower{
      display: none;
    }
  }
}