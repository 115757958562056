@import '../../styles/media.mixins';
@import '../../styles/variables';

.anchors-wrapper {
  min-height: 210px;
  width: 100%;
  max-width: 1920px;
  background: #d4d2d3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  @include tablet-and-lower {
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
  }
  .info {
    color: #58595b;
    font-family: ExoSoftBold, sans-serif;
    font-size: $font-size-md;
    flex-basis: 27%;
    @include tablet-and-lower{
      margin-bottom: 40px;
      text-align: center;
    }
  }
  .anchors-list{
    display: flex;
    flex-basis: 70%;
    justify-content: space-around;

    @include tablet-and-lower {
      flex-wrap: wrap;
      flex-basis: 70%;
    }

  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-family: ExoSoftMedium, sans-serif;
    font-size: $font-size-lg;
    color: #fff;
    width: $link-img-width;
    height: $link-img-height;
    @include tablet-and-lower{
      margin-bottom: 15px;
      width: $link-img-width-mobile;
      height: $link-img-height-mobile;
    }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        text-align: center;
        transform: scale(1);
        max-width: 80%;
      }
      &:hover{
        p{
          transform: scale(1.05);
          transition: all 300ms;
        }
      }
    }
  }
  .illegal-actions {
    background: url("../../images/Illegal_actions.png") no-repeat;
    background-size: cover;
  }
  .conflict-situations {
    background: url("../../images/Conflict_situations.png") no-repeat;
    background-size: cover;
  }
  .working-conditions {
    background: url("../../images/Working_conditions.png") no-repeat;
    background-size: cover;
  }
}