@import '../../styles/media.mixins';
@import '../../styles/variables';

.appeal-banner {
  background: #ebebeb;
  color: #414042;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: $font-size-md;
  padding: 60px 30px 30px;
  @include tablet-and-lower{
    padding-top: 30px;
  }

  .appeal-info {
    font-family: "ExoSoftLightItalic", sans-serif;
    text-align: center;
    padding-bottom: 30px;
  }

  .appeal-sub {
    font-family: "ExoSoftMedium", sans-serif;
    text-align: center;
    text-transform: uppercase;
  }
}