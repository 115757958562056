@import '../../styles/media.mixins';
@import '../../styles/variables';

header {
  font-size: $font-size-md;
  width: 100%;
  color: white;
  display: flex;
  box-shadow: 0 4px 3px 0 #000000;
  align-items: center;
  justify-content: center;
  background: #464646;
  margin-bottom: 4px;
  padding: 10px 20px;
  @include minimal-header {
    flex-direction: column;
    padding: 10px 5px;
  }
  .contacts{
    margin-right: auto;
    @include minimal-header {
      margin: 0 0 20px;
      order: 1;
    }
  }
  ul {
    list-style-type: none;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include tablet-and-lower {
      padding: 15px 0;
    }
    @include minimal-header {
      flex-direction: column;
      padding: 0 10px;
      order: 2;
    }

    li:first-child {
      padding-left: 0;
      white-space: nowrap;
      @include minimal-header {
        padding: 0;
        margin-bottom: 10px;
        text-align: center;
      }
    }
    li:last-child {
      @include minimal-header {
        padding: 0;
        margin-bottom: 0;
        text-align: center;
      }
    }

    li {
      a{
        color: #fff;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
      @include minimal-header {
        padding: 0;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }

  .vl{
    border-left: 1px solid #ffffff;
    margin-left: 50px;
    padding: 20px 25px;
    @include small-desktop{
      padding: 15px 10px;
      margin-left: 20px;
    }
    @include minimal-header {
      display: none;
    }
  }

  .phone-number-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .phone-number-descrition {
      font-size: 12px;
    }
  }

  .phone-number:before {
    content: "";
    display: block;
    background: url("../../icons/phone.png") no-repeat;
    background-size: contain;
    float: left;
    width: 25px;
    height: 25px;
    margin: 0 20px 0 0;
    @include small-desktop {
      width: 23px;
      height: 23px;
    }
    @include tablet {
      width: 20px;
      height: 20px;
    }
    @include minimal-header {
      margin: 0 10px 0 0;
    }
  }

  .mail:before {
    content: "";
    display: block;
    background: url("../../icons/mail.png") no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    float: left;
    margin: 0 20px 0 0;
    @include tablet {
      width: 20px;
      height: 20px;
    }
  }

  .logo {
    margin-left: auto;
    @include small-desktop {
      margin-right: 10px;
    }
    @include tablet {
      margin-right: 10px;
    }
    @include minimal-header {
      margin: auto auto 10px;
    }

  }

  .img-wrapper {
    margin-left: auto;
    height: 60px;
    @include small-desktop {
      height: 55px;
    }
    @include minimal-header {
      height: 65px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
}