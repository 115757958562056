
$app-max-width: 1032px;
$app-padding-content: 30px;

$app-mobile-min-width: 720px;
$app-mobile-min-height: 1280px;

$font-color: #ffffff;

$app-background-light: #ebebeb;
$app-background-bright: #d4d2d3;
$app-label-background: #ffc7c8;
$app-label-star: #ee635b;
$app-color-green: #74bf44;


$modal-color-header: #b9dfa1;
$modal-color-button-active: #464646;
$modal-color-button-disable: #c8c8c8;
$modal-default-width: 760px;
$modal-default-height: 300px;
$modal-button-width: 200px;
$modal-button-height: 60px;

$header-color: #464646;
$header-height: 115px;

$form-feed-back-height-max: 1260px;
$form-feed-back-border: 15px;
$form-feed-back-btn-w: 270px;
$form-feed-back-btn-h: 50px;
$form-feed-back-input-h: 40px;


$link-img-width: 190px;
$link-img-height: 190px;

$link-img-width-mobile: 260px;
$link-img-height-mobile: 260px;

$font-size-xl: 94px;
$font-size-lg: 20px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;




