@import "../../styles/variables";
@import '../../styles/media.mixins';

.feed-back {
  padding-left: $app-padding-content;
  padding-right: $app-padding-content;
  padding-top: 25px;

  @include tablet {
    padding: 0;
  }
  max-width: 100%;

  .feed-back-form-wrapper {
    box-sizing: border-box;
    max-width: 100%;
    font-size: $font-size-md;
    border: calc(#{$form-feed-back-border}) solid $header-color;
    color: $font-color;
    display: flex;
    align-content: center;
    align-items: center;

    @include tablet {
      font-size: $font-size-sm;
      border-width: calc(#{$form-feed-back-border});
    }

    .feed-back-form {
      margin: 15px;
      height: 100%;
      width: calc(#{$app-max-width} - 120px);
      background: $header-color;
      padding: $form-feed-back-border;
      display: flex;
      flex-direction: column;

      @include tablet {
        padding: calc(#{$form-feed-back-border});
        height: auto;
      }
      @include small-desktop-over {
      }

      .icon-down-chevron {
        color: $header-color;
      }

      .inform-about, .position {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: $form-feed-back-input-h;
        margin-bottom: calc(#{$form-feed-back-border});

        @include tablet {
          flex-wrap: wrap;
          height: 80px;
          margin-bottom: 0;
        }

        .label-wrap {
          display: flex;
          height: $form-feed-back-input-h;
          align-items: center;
          min-width: 28%;
          width: 28%;
          padding-right: 10px;

          .label-inform {
            width: auto;

            &.required-field:after {
              content: '*';
              display: inline;
              padding-left: 5px;
              color: $app-label-star;
            }
          }

          @include tablet {
            white-space: nowrap;
            height: $form-feed-back-input-h;
            width: auto;
          }
        }

        .select-wrap {
          width: 100%;

          .my-select {
            .selectField {
              max-width: 635px;

              &__control {
                border-radius: 0;
                border: none;
                min-height: 40px;
                box-shadow: none;
                cursor: pointer;
              }

              &__placeholder, &__single-value, &__menu, &__input > input {
                font-family: ExoSoftMedium, sans-serif;
                font-size: $font-size-sm !important;
              }

              &__value-container {
                height: 40px;
                padding-left: 16px;
              }

              &__indicator-separator {
                display: none;
              }

              &__menu {
                color: $header-color;
                border-radius: 0;
              }
            }

            &.error {
              div {
                .selectField__control {
                  background: $app-label-background;
                }

                div.selectField__menu {
                  background: white;
                }
              }
            }

            div {
              div {
                ul {
                  li:first-child {
                    padding: 0;
                    height: 0;
                  }
                }
              }
            }
          }
        }

      }

      .text-inform {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 150px;
        margin-bottom: $form-feed-back-border;

        &.question-one {
          height: auto;

          .scrollable-text-box {
            font-family: ExoSoftMedium, sans-serif;
            font-size: $font-size-sm;
            @include tablet {
              height: 100px;
              width: 100%;
            }
            @include small-desktop-over {
              height: 100px;
              width: 100%;
            }
          }
        }

        @include tablet {
          flex-wrap: wrap;
          height: 200px;
          margin-bottom: 0;
        }

        .label-wrap-inform {
          display: flex;
          align-items: center;
          min-width: 28%;
          width: 28%;
          padding-right: 10px;
          @include tablet {
            height: $form-feed-back-input-h;
            width: auto;
          }

          .label-text-inform {
            width: auto;

            &.required-field:after {
              content: '*';
              display: inline;
              padding-left: 5px;
              color: $app-label-star;
            }
          }
        }

        .scrollable-text-box {
          font-family: ExoSoftMedium, sans-serif;
          width: 100%;
          height: 100%;
          font-size: $font-size-sm;
          color: $header-color;
          padding: 16px 5px 16px 16px;

          resize: none;
          cursor: text;

          &.error {
            outline: none;
            border-color: rgb(0, 0, 0);
            background: #ffc7c8;

            &:active {
              outline: #ff0e00;
            }

            &:focus {
              box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
              transition: all 100ms linear;
            }

            &::placeholder {
              color: $modal-color-button-active;
              font-family: ExoSoftLight, sans-serif;
            }
          }

          @include tablet {
            height: 150px;
            width: 100%;
          }
          @include small-desktop-over {
            height: 150px;
            width: 100%;
          }
        }
      }

      .pib {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: $form-feed-back-input-h;
        margin-bottom: $form-feed-back-border;

        @include tablet {
          flex-wrap: wrap;
          height: 100px;
          margin-bottom: 0;
        }

        .pib-wrap {
          display: flex;
          height: $form-feed-back-input-h;
          align-items: center;
          min-width: 28%;
          width: 28%;
          padding-right: 10px;
          @include tablet {
            height: $form-feed-back-input-h;
            width: auto;
          }
        }

        .pib-text-box {
          font-family: ExoSoftMedium, sans-serif;
          height: $form-feed-back-input-h;
          width: 100%;
          padding-left: 16px;
          font-size: $font-size-sm;
          color: $header-color;
          resize: none;
          cursor: text;

          @include tablet {
            text-overflow: ellipsis;
          }
          @include small-desktop-over {
            text-overflow: ellipsis;
          }
        }
      }

      .contacts, .unit, .time-period {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: $form-feed-back-input-h;
        margin-bottom: $form-feed-back-border;
        @include tablet {
          flex-wrap: wrap;
          height: 100px;
          margin-bottom: 0;
        }

        .label-container-cont {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-width: 28%;
          width: 28%;
          height: $form-feed-back-input-h;
          padding-right: 10px;
          @include tablet {
            display: inline-block;
            height: $form-feed-back-input-h;
            width: auto;
          }

          .label-container-cont-top {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            .label-contacts {

              &.required-field:after {
                content: '*';
                display: inline;
                padding: 0 5px 0 5px;
                color: $app-label-star;
              }
            }
          }
        }

        .contacts-text-box {
          font-family: ExoSoftMedium, sans-serif;
          width: 100%;
          height: $form-feed-back-input-h;
          padding-left: 16px;
          font-size: $font-size-sm;
          color: $header-color;
          resize: none;
          cursor: text;

          &.error {
            background: $app-label-background;
            border-color: black;
            border-width: 1px;

            &:focus {
              outline: none;
              box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.2);
              transition: all 100ms linear;
            }

            &::placeholder {
              color: $modal-color-button-active;
              font-family: ExoSoftLight, sans-serif;
            }
          }
        }
      }

      .questions {
        height: 85px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: $form-feed-back-border;

        @include tablet {
          flex-direction: column;
          box-sizing: border-box;
          width: 100%;
          height: auto;
          margin-bottom: 0;
        }

        @include min-mobile {
          height: auto;
        }

        .empty-el {
          width: auto;
        }

        .question-two {
          .left-side-question {

            @include tablet {
              max-width: 190px;
              min-width: 180px;
            }

            @include small-desktop-over {
              max-width: 190px;
              min-width: 180px;
            }
          }
        }

        .question-three {
          .left-side-question {
            @include small-desktop-over {
              max-width: 230px;
              min-width: 230px;
            }
          }

          .right-side-question {
            @include tablet {
              margin-right: 0;
            }
          }
        }

        .question-two, .question-three {
          display: flex;
          height: 100%;

          @include tablet {
            width: 100%;
            margin-bottom: $form-feed-back-border;
            justify-content: space-between;
          }
          @include min-mobile {
            display: flex;
            flex-direction: column;
            height: auto;
          }

          .left-side-question {
            height: 100%;
            display: flex;
            align-items: center;
            @include tablet {
              width: 211px;
              min-width: 172px;
              padding-right: 10px;
            }

            .label-question-two, .label-question-three {

              &.required-field:after {
                content: '*';
                display: inline;
                padding-left: 5px;
                color: $app-label-star;
              }
            }
          }

          .right-side-question {
            height: 100%;
            display: flex;
            flex-direction: column;

            justify-content: center;
            @include tablet {
              display: flex;
              flex-direction: column;
            }
            @include min-mobile {
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            }

            .container {
              display: block;
              position: relative;
              padding-left: 35px;
              padding-top: 10px;
              padding-bottom: 10px;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            .container input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;

            }

            .checkmark {
              position: absolute;
              top: 10px;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee;

              &.error {
                background: $app-label-background;
              }
            }

            .container:hover input ~ .checkmark {
              background-color: #ccc;
            }

            .container input:checked ~ .checkmark {
              background-color: $app-color-green;
            }

            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            .container input:checked ~ .checkmark:after {
              display: block;
            }

            .container .checkmark:after {
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }

      .text-inf {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: $font-size-sm;
        @include tablet {
          font-size: $font-size-xs;
        }
      }

      .form-bottom {
        height: auto;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: $form-feed-back-border;

        &.hidden-recaptcha {
          display: flex;
          justify-content: flex-end;
        }

        @include tablet {
          flex-direction: column;
          align-items: center;
        }

        .captcha {
          @include tablet {
            margin-bottom: $form-feed-back-border;
          }
        }

        .feed-back-form-submit {
          font-family: ExoSoftMedium, sans-serif;
          width: $form-feed-back-btn-w;
          height: $form-feed-back-btn-h;
          background: $modal-color-button-disable;
          display: flex;
          box-sizing: border-box;
          border: solid 0;
          font-size: $font-size-lg;
          color: $app-background-light;
          justify-content: center;
          align-items: center;
          @include tablet {
            flex-direction: column;
            margin-left: 0;
            width: 100%;
            max-width: $form-feed-back-btn-w;
            min-width: max-content;
          }
        }

        .verified {
          background: $app-color-green;
          outline: none;

          &:active {
            box-shadow: inset 0 0 10px 0 #3f7b18;
            cursor: pointer;
          }

          &:hover {
            background: #7cce48;
            cursor: pointer;
            transition: all 0.1s linear;
          }
        }
      }

    }
  }
}


.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;

  .modal-body-p {
    font-size: $font-size-md;
    margin-bottom: 20px;
  }

  .checked-img {
    width: 50px;
  }
}

