@import '../../../styles/media.mixins';

.modal-wrapper {
  padding: 0;
  border: 5px solid #74bf44;
  max-width: 690px;
  @include tablet-and-lower {
    max-width: 100%;
  }
  .modal-header {
    text-transform: uppercase;
    font-family: ExoSoftMedium, sans-serif;
    font-size: 21px;
    text-align: left;
    background: #b9dfa1;
    color: #fff;
    padding: 5px 10px;
    &.modal-header-registered{
      @include tablet-and-lower{
        font-size: 18px;
      }
    }
  }
  .modal-body {
    padding: 20px 30px 30px 30px;
    font-family: ExoSoft, sans-serif;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 140px;
    text-align: center;
  }
  .button-list {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    @include tablet-and-lower {
      flex-direction: column;
      margin-top: 15px;
    }
    button {
      text-transform: uppercase;
      background: #464646;
      font-family: ExoSoftLight, sans-serif;
      font-size: 20px;
      outline: none;
      box-shadow: 7px 7px 15px 0px rgba(0, 0, 0, 0.75);
      color: #fff;
      cursor: pointer;
      border: none;
      margin: 0 15px;
      padding: 10px 20px;
      min-width: 180px;
      @include tablet-and-lower {
        margin: 10px 0;
      }
      &:hover{
        background: #c8c8c8;
        transition: background 100ms linear;
      }
      &:active{
        box-shadow: inset 1px 1px 10px #333;
      }
    }
  }
  .modal-close-btn {
    outline: none;
    cursor: pointer;
    color: #fff;
    top: 0;
    right: 0;
  }

  .modal-close-icon {
    path {
      fill: #fff;
    }
  }
}

.modal-enter-opacity-anim {
  opacity: 0;
}
.modal-enter-opacity-anim.modal-enter-active {
  opacity: 1;
  transition: all 200ms ease-in;
}
.modal-leave-opacity-anim {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

